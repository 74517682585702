import React from "react";
import ReactDOM from "react-dom";
import { View } from "./components/view";
import { setBaseStyling } from "./global.styles";

setBaseStyling();

ReactDOM.render(
  <React.StrictMode>
    <View />
  </React.StrictMode>,
  document.getElementById("root")
);
