import React, { FC, useEffect } from "react";
import { Singleton } from "react-singletons";
import { style } from "typestyle";
import closeIcon from "../assets/svg/close.svg";
import { colors } from "../constants/colors";
import { slideInAnimation } from "../styles/animations";

const closeOnEscape = (e: KeyboardEvent) => {
  if (e.keyCode === 27) {
    infoOverlay.unmount();
  }
};

export const InfoOverlay: FC = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("keyup", closeOnEscape);

    return () => {
      document.body.style.overflow = "initial";
      document.removeEventListener("keyup", closeOnEscape);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.controlBar}>
          <img
            src={closeIcon}
            alt="sluit"
            className={styles.close}
            onClick={() => infoOverlay.unmount()}
          />
        </div>

        <h1>
          <span className={styles.highlight}>Waarom</span> blijven we binnen?
        </h1>
        <p>
          We hebben iedereen in Nederland nodig om verspreiding van het
          coronavirus tegen te gaan. De essentie van de aanpak is: het virus
          maximaal controleren waarbij we vooral kwetsbare groepen willen
          beschermen. Als mensen geen contact hebben, dan kunnen ze anderen niet
          besmetten en verspreidt het coronavirus zich niet. (
          <a
            href="https://www.rijksoverheid.nl/onderwerpen/coronavirus-covid-19/veelgestelde-vragen-over-de-aanpak-van-het-nieuwe-coronavirus-in-nederland"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bron
          </a>
          )
        </p>

        <p>De maatregelen op een rij:</p>
        <ul>
          <li>Was uw handen regelmatig</li>
          <li>Hoest en nies in de binnenkant van uw elleboog</li>
          <li>Gebruik papieren zakdoekjes</li>
          <li>Schud geen handen</li>
          <li>Houd 1,5 meter afstand</li>
          <li>Blijf zoveel mogelijk thuis</li>
        </ul>
        <p>
          Alle informatie over COVID-19 is beschikbaar via de website van het{" "}
          <a
            href="https://www.rivm.nl/coronavirus-covid-19"
            target="_blank"
            rel="noopener noreferrer"
          >
            RIVM
          </a>{" "}
          en de{" "}
          <a
            href="https://www.rijksoverheid.nl/onderwerpen/coronavirus-covid-19/veelgestelde-vragen-over-de-aanpak-van-het-nieuwe-coronavirus-in-nederland"
            target="_blank"
            rel="noopener noreferrer"
          >
            overheid
          </a>
          .
        </p>

        <h1>
          De rol van de <span className={styles.highlight}>overheid</span>
        </h1>
        <p>
          De overheid is 24/7 bezig met het coronavirus en welke maatregelen
          nodig zijn om zoveel mogelijk mensen te beschermen. Hier wordt
          regelmatig over gedebatteerd in de Tweede Kamer. Ook is er wekelijks
          een update of persconferentie beschikbaar om de huidige stand van
          zaken aan de bevolking te vertellen en eventuele nieuwe of aangepaste
          maatregelen door te geven.
        </p>

        <h1>Overige informatie</h1>
        <p>Andere vragen waar het antwoord heel simpel op is:</p>
        <ul>
          <li>
            Is er bekend wanneer alles weer normaal wordt? Nee
            <span className={styles.highlight}>.</span>
          </li>
          <li>
            Is het niet gewoon een griepje? Nee
            <span className={styles.highlight}>.</span>
          </li>
          <li>
            Is er een verband tussen coronavirus en 5G? Nee
            <span className={styles.highlight}>.</span>
          </li>
        </ul>

        <div className={styles.footer}>
          <a
            href="https://martindrost.nl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Martin Drost
          </a>{" "}
          - 2020
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: style({
    animationName: slideInAnimation,
    animationDuration: ".3s",

    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 999,
    background: colors.background,
    overflowY: "auto",
  }),

  content: style({
    position: "relative",
    maxWidth: 800,
    margin: "0 auto",
    padding: 50,
  }),

  controlBar: style({
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    marginBottom: 20,
  }),

  close: style({
    cursor: "pointer",
    opacity: 0.54,
    transition: ".3s opacity",

    $nest: {
      "&:hover": {
        opacity: 0.8,
      },
    },
  }),

  highlight: style({
    color: colors.primary,
  }),

  footer: style({
    margin: 60,
    textAlign: "center",
  }),
};

export const infoOverlay = new Singleton<void>(InfoOverlay);
