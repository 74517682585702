import React, { FC } from "react";
import { style } from "typestyle";
import background from "../assets/images/background.jpg";
import infoIcon from "../assets/svg/info-rounded.svg";
import { colors } from "../constants/colors";
import { infoOverlay } from "./info-overlay";
import { RutteQuote } from "./rutte-quote";
import { StatusIndicator } from "./status-indicator";

export const View: FC = () => (
  <div className={styles.container}>
    <div />
    <StatusIndicator />
    <div className={styles.footer}>
      <RutteQuote />

      <div className={styles.infoBar}>
        <img
          src={infoIcon}
          alt="info"
          className={styles.infoButton}
          onClick={() => infoOverlay.mount()}
        />
      </div>
    </div>
  </div>
);

const styles = {
  container: style({
    minHeight: "100vh",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    $nest: {
      "& > *": {
        zIndex: 1,
      },
      "&:before": {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        content: "''",
        backgroundColor: colors.background,
        opacity: 0.85,
      },
    },
  }),

  footer: style({
    maxWidth: 650,
  }),

  infoBar: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    margin: 25,
  }),

  infoButton: style({
    display: "block",
    opacity: 0.54,
    transition: ".3s opacity",

    $nest: {
      "&:hover": {
        opacity: 0.8,
      },
      "&, & img": {
        cursor: "pointer",
      },
    },
  }),
};
