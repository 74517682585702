import { keyframes } from "typestyle";

export const slideInAnimation = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateY(-0px)",
  },
  "100%": {
    opacity: 1,
    transform: "translateY(0px)",
  },
});
