import React, { FC } from "react";
import { style } from "typestyle";
import markrutte from "../assets/images/mark-rutte.png";
import { colors } from "../constants/colors";

export const RutteQuote: FC = () => (
  <div className={styles.container}>
    <img width={65} src={markrutte} alt="Mark Rutte" />
    <div className={styles.message}>
      <div className={styles.quote}>
        De maatregelen worden versoepeld maar we moeten alert blijven. Bekijk{" "}
        <a
          href="https://www.rijksoverheid.nl/documenten/publicaties/2020/05/06/factsheet-maatregelen-corona"
          rel="noopener noreferrer"
          target="_blank"
        >
          de factsheet
        </a>{" "}
        voor meer informatie.
      </div>
    </div>
  </div>
);

const styles = {
  container: style({
    display: "flex",
    alignItems: "center",
    margin: 40,
  }),

  message: style({
    marginLeft: 30,
  }),

  quote: style({
    padding: 15,
    border: `1px solid ${colors.text}`,
    borderRadius: 6,
  }),

  countdown: style({
    fontSize: 12,
    opacity: 0.54,
    margin: "10px 15px 0 0",
    textAlign: "right",
  }),
};
