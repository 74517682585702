import React, { FC } from "react";
import { style } from "typestyle";
import { colors } from "../constants/colors";

export const StatusIndicator: FC = () => (
  <div className={styles.status}>
    Nee<span className={styles.dot}>.</span>
  </div>
);

const styles = {
  status: style({
    fontSize: 24,
    fontWeight: 500,
  }),

  dot: style({
    color: colors.primary,
  }),
};
