import { cssRaw, cssRule } from "typestyle";
import { colors } from "./constants/colors";

export const setBaseStyling = () => {
  cssRaw(`
      body {
        "-webkit-font-smoothing": "antialiased",
        " -moz-osx-font-smoothing": "grayscale",
      }
    `);

  cssRule("body", {
    background: colors.background,
    margin: 0,
    padding: 0,
    fontSize: 14,
    lineHeight: "24px",
  });

  cssRule("p, ul", {
    opacity: 0.87,
  });

  cssRule("p", {
    textAlign: "justify",
  });

  cssRule("a", {
    textDecoration: "none",
    cursor: "pointer",
    opacity: 0.54,
    transition: ".3s opacity",

    $nest: {
      "&:hover": {
        opacity: 0.8,
      },
    },
  });

  cssRule("h1", {
    marginTop: 40,
    fontSize: 24,
    fontWeight: 500,
  });

  cssRule("*", {
    boxSizing: "border-box",
    fontFamily: "'Work Sans', sans-serif",
    color: colors.text,
    cursor: "default",
  });
};
